import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { addAuthentication } from '../../../components/addAuthentication';
import './scss/CancelPlanPage.scss';
import {
  CancelSubscriptionPlan,
  DashboardPageHeading,
} from '../../../components/dashboard';
import { DashboardLayout } from '../../../components/layout';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default addAuthentication(() => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Amwell Private Practice | Dashboard - Cancel Plan</title>
    </Helmet>
    <DashboardLayout>
      <div className="CancelPlanPage">
        <DashboardPageHeading
          heading={'Plan & Payment'}
          links={[{ label: 'My Plan & Payment', to: '/dashboard/plan' }]}
          partiallyActive={true}
        />
        <div className="CancelPlanPage-container">
          <CancelSubscriptionPlan />
        </div>
      </div>
    </DashboardLayout>
  </>
));
